<template>
  <div class="list">
    <iq-empty-state
      icon="folder"
      title="Keine Projekte gefunden..."
      v-if="isEmptyStateActive"
    />

    <!-- Pending -->
    <div v-if="pendingProjects.length > 0" class="list-holder">
      <div class="md-caption">Ausstehende Projekte</div>

      <div class="item" v-for="item in pendingProjects" :key="item.id" @click="redirect({ name: 'Project', params: { id: item.id } })">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon">
            <md-icon class="md-primary">folder</md-icon>
          </md-avatar>
        </div>

        <!-- Title, description and created date -->
        <div>
          <div class="md-title">{{ item.name }}</div>
          <div class="md-body-2">{{ item.description }}</div>
          <div class="md-caption">{{ getDateTimeString(item.created_at) }}</div>
        </div>

        <!-- Button -->
        <md-button
          class="md-primary md-icon-button"
          @click="redirect({ name: 'Project', params: { id: item.id } })"
        >
          <md-icon>chevron_right</md-icon>
          <md-tooltip md-direction="left"
            >Projekt NAME-OF-THE-PROJECT öffnen</md-tooltip
          >
        </md-button>
      </div>
    </div>

    <!-- Active -->
    <div v-if="activeProjects.length > 0" class="list-holder">
      <div class="md-caption">Aktive Projekte</div>

      <div class="item" v-for="item in activeProjects" :key="item.id" @click="redirect({ name: 'Project', params: { id: item.id } })">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon">
            <md-icon class="md-primary">folder</md-icon>
          </md-avatar>
        </div>

        <!-- Title, description and created date -->
        <div>
          <div class="md-title">{{ item.name }}</div>
          <div class="md-body-2">{{ item.description }}</div>
          <div class="md-caption">{{ getDateTimeString(item.created_at) }}</div>
        </div>

        <!-- Button -->
        <md-button
          class="md-primary md-icon-button"
          @click="redirect({ name: 'Project', params: { id: item.id } })"
        >
          <md-icon>chevron_right</md-icon>
          <md-tooltip md-direction="left"
            >Projekt {{ item.name }} öffnen</md-tooltip
          >
        </md-button>
      </div>
    </div>

    <!-- Finished -->
    <div v-if="finishedProjects.length > 0" class="list-holder">
      <div class="md-caption">Ausstehende Projekte</div>

      <div
        class="item"
        v-for="item in finishedProjects"
        :key="item.id"
        @click="redirect({ name: 'Project', params: { id: item.id } })"
      >
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon">
            <md-icon class="md-primary">folder</md-icon>
          </md-avatar>
        </div>

        <!-- Title, description and created date -->
        <div>
          <div class="md-title">{{ item.name }}</div>
          <div class="md-body-2">{{ item.description }}</div>
          <div class="md-caption">{{ getDateTimeString(item.created_at) }}</div>
        </div>

        <!-- Button -->
        <md-button
          class="md-primary md-icon-button"
          @click="redirect({ name: 'Project', params: { id: item.id } })"
        >
          <md-icon>chevron_right</md-icon>
          <md-tooltip md-direction="left"
            >Projekt {{ item.name }} öffnen</md-tooltip
          >
        </md-button>
      </div>
    </div>

    <!-- Inactive -->
    <div v-if="inactiveProjects.length > 0" class="list-holder">
      <div class="md-caption">Ausstehende Projekte</div>

      <div class="item" v-for="item in inactiveProjects" :key="item.id" @click="redirect({ name: 'Project', params: { id: item.id } })">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon">
            <md-icon class="md-primary">folder</md-icon>
          </md-avatar>
        </div>

        <!-- Title, description and created date -->
        <div>
          <div class="md-title">{{ item.name }}</div>
          <div class="md-body-2">{{ item.description }}</div>
          <div class="md-caption">{{ getDateTimeString(item.created_at) }}</div>
        </div>

        <!-- Button -->
        <md-button
          class="md-primary md-icon-button"
          @click="redirect({ name: 'Project', params: { id: item.id } })"
        >
          <md-icon>chevron_right</md-icon>
          <md-tooltip md-direction="left"
            >Projekt {{ item.name }} öffnen</md-tooltip
          >
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import routingMixin from "@/mixins/routingMixin";

export default {
  name: "orderedProjectList",
  mixins: [routingMixin],
  props: {
    pendingProjects: { type: Array, required: true },
    activeProjects: { type: Array, required: true },
    finishedProjects: { type: Array, required: true },
    inactiveProjects: { type: Array, required: true },
  },
  computed: {
    isEmptyStateActive() {
      return (
        this.pendingProjects.length === 0 &&
        this.activeProjects.length === 0 &&
        this.finishedProjects.length === 0 &&
        this.inactiveProjects.length === 0
      );
    },
  },
  methods: {
    getDateTimeString(date) {
      return `Erstellt am ${new Date(date).toLocaleDateString()} um ${new Date(
        date
      ).toLocaleTimeString()}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/main";
@import "../../assets/css/mixins";

.list > .list-holder > .md-caption {
  color: var(--iq-blue-500);
  margin: 1rem 0;
}

.list-holder > .item:not(:first-child) {
  margin-top: 1rem;
}

.iq-empty-state {
  margin-top: 1rem;
}

.item {
  background-color: white;
  padding: $padding;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include borderHover;

  & > .md-button {
    margin: 0 0 0 auto;
  }
}
</style>
