<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="loading" />

    <div class="main-container" v-if="!loading">
      <div class="main-wrapper">
        <!-- Greeting -->
        <div class="md-display-1" style="color: #202020; margin-bottom: 1rem">
          {{ getGreeting }} {{ user.first_name }} {{ user.last_name }}!
        </div>

        <!-- Project overview -->
        <project-status-overview
          :active="count.Active"
          :pending="count.Pending"
          :finished="count.Finished"
          :inactive="count.Inactive"
          v-if="isComponentActive(['admin'])"
        />

        <!-- Ordered project list -->
        <ordered-project-list
          :active-projects="projects.active"
          :pending-projects="projects.pending"
          :finished-projects="projects.finished"
          :inactive-projects="projects.inactive"
        />
      </div>
    </div>
  </iq-main>
</template>

<script>
import ProjectStatusOverview from "@/components/dashboard/projectStatusOverview";
import loadingMixin from "@/mixins/loadingMixin";
import { getUser, getUserProjects } from "@/services/api/user";
import OrderedProjectList from "@/components/dashboard/orderedProjectList";
import { getProjectCountByStatus } from "@/services/api/statistics";
import accessControlMixin from "@/mixins/accessControlMixin";

export default {
  name: "Dashboard",
  mixins: [loadingMixin, accessControlMixin],
  components: { OrderedProjectList, ProjectStatusOverview },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
      },
      projects: {
        pending: [],
        active: [],
        finished: [],
        inactive: [],
      },
      count: {
        Active: 0,
        Inactive: 0,
        Pending: 0,
        Finished: 0,
      },
    };
  },
  computed: {
    getGreeting() {
      let greeting;
      let now = Date.now();
      let hour = new Date(now).getHours();
      if (hour >= 0 && hour < 10) greeting = "Guten morgen";
      else if (hour >= 10 && hour < 13) greeting = "Hallo";
      else if (hour >= 13 && hour < 17) greeting = "Guten Nachmittag";
      else if (hour >= 17 && hour <= 23) greeting = "Guten Abend";
      return greeting;
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      try {
        this.user = await getUser();
        let projects = await getUserProjects();
        this.count = await getProjectCountByStatus();
        projects.forEach((project) => {
          if (project.status === "pending") this.projects.pending.push(project);
          if (project.status === "active") this.projects.active.push(project);
          if (project.status === "finished")
            this.projects.finished.push(project);
          if (project.status === "inactive")
            this.projects.inactive.push(project);
        });
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/mixins";

.main-container {
  padding: 1rem;

  & > .main-wrapper {
    margin-bottom: 1rem;
  }
}
</style>
