import Interceptor from "@/services/Interceptor";
import Settings from "@/config";

const STATISTICS_URI = Settings.API_URI_STATISTICS;

/**
 * Get the count of projects ordered by status
 **/
const getProjectCountByStatus = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${STATISTICS_URI}/project/count`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export { getProjectCountByStatus };
