<template>
  <iq-card
    title="Überblick über den Projektstatus"
    button-icon="chevron_right"
    button-tooltip="Projekte ansehen"
    @buttonClick="redirect({ name: 'Projects' })"
  >
    <div class="flow-container">
      <div class="flow-container--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon pending-avatar">
            <md-icon style="color: var(--iq-gray-500)">pending</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ pending }}</div>

        <!-- Description -->
        <div class="md-title">Ausstehend</div>
      </div>

      <div class="flow-container--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon active-avatar">
            <md-icon style="color: var(--iq-green-500)">play_arrow</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ active }}</div>

        <!-- Description -->
        <div class="md-title">Aktiv</div>
      </div>

      <div class="flow-container--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon finished-avatar">
            <md-icon style="color: var(--iq-blue-500)"
              >check_circle_outline</md-icon
            >
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ finished }}</div>

        <!-- Description -->
        <div class="md-title">Abgeschlossen</div>
      </div>

      <div class="flow-container--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon inactive-avatar">
            <md-icon style="color: var(--iq-red-500)">stop</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ inactive }}</div>

        <!-- Description -->
        <div class="md-title">Inaktiv</div>
      </div>
    </div>
  </iq-card>
</template>

<script>
import routingMixin from "@/mixins/routingMixin";

export default {
  name: "projectStatusOverview",
  mixins: [routingMixin],
  props: {
    active: { type: Number, required: true },
    inactive: { type: Number, required: true },
    pending: { type: Number, required: true },
    finished: { type: Number, required: true },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/main";

.flow-container {
  padding: $padding;
  display: flex;

  &--item {
    flex: 1;
    height: 200px;
    display: flex;
    position: relative;
    border-radius: $border-radius;
    padding-left: 2rem;
    align-items: center;
    color: var(--iq-gray-400);
    transition: $transition;

    &:hover {
      background-color: var(--iq-gray-50);

      &:first-child {
        color: var(--iq-gray-500);
      }

      &:nth-child(2) {
        color: var(--iq-green-500);
      }

      &:nth-child(3) {
        color: var(--iq-blue-500);
      }

      &:last-child {
        color: var(--iq-red-500);
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      border-radius: 0 4px 4px 0;
      height: 25%;
      left: 0;
      background-color: var(--iq-gray-300);
      transition: $transition;
    }

    &:hover:before {
      width: 4px;
      height: 50%;
    }

    &:first-child:hover:before {
      background-color: var(--iq-gray-500);
    }

    &:nth-child(2):hover:before {
      background-color: var(--iq-green-500);
    }

    &:nth-child(3):hover:before {
      background-color: var(--iq-blue-500);
    }

    &:last-child:hover:before {
      background-color: var(--iq-red-500);
    }
  }
}

// Avatar adjuster
.pending-avatar {
  background-color: var(--iq-gray-100) !important;
}

.active-avatar {
  background-color: var(--iq-green-100) !important;
}

.inactive-avatar {
  background-color: var(--iq-red-100) !important;
}

.finished-avatar {
  background-color: var(--iq-blue-100) !important;
}

.title-adjuster {
  font-size: 48px;
  font-weight: 400;
  margin-right: 1rem;
}
</style>
